import React from 'react'
import { SingleAvatar } from 'atoms'
import { formatDate } from 'lib/utils/date/formatDate'

type ChildProps = {
  firstName: string
  lastName: string
  displayDate?: any
  customClassName?: string
  text?: string
  dataTestId?: string
}

const PostedComment: React.FC<ChildProps> = ({
  firstName,
  lastName,
  displayDate,
  customClassName,
  text,
  dataTestId
}) => {

  return (
    <div className={customClassName} data-testid={dataTestId}>
      <div className='v-center'>
        <SingleAvatar size={32} label={`${firstName} ${lastName}`} />
        <span className='body2-regular grey-700 ml-3'>{displayDate && formatDate({ date: displayDate, withTime:true })}</span>
      </div>
      <div className='pl-7 body1-regular'>
        {text}
      </div>
    </div>
  )
}

export default PostedComment
