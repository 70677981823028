import React from 'react'
import { Card as CardUI } from '@teamfabric/copilot-ui'

export type CardProps = {
  showDivider?: boolean
  headerContent?: any
  bodyContent?: any
  showCollapse?: boolean
  open?: boolean
  style?: any
  customBodyPadding?: string
}

const Card: React.FC<CardProps> = ({
  headerContent,
  bodyContent,
  showCollapse,
  showDivider = false,
  open = true,
  ...props
}) => {
  return (
    <CardUI
      header={headerContent}
      body={bodyContent}
      showCollapse={showCollapse}
      open={open}
      showDivider={showDivider}
      {...props}
    />
  )
}

export default Card
