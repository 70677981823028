import React, { useState } from 'react'
import { Drawer } from '@teamfabric/copilot-ui'
import { Box, Button, HorizontalTab } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import ProposalIssueStatusPresenter from '../presenters/issueStatus'
import { formatDate } from 'lib/utils/date/formatDate'
import GridLabelValuePair from 'molecules/modal/gridLabelValuePair'
import CloseIssueModal from '../modals/issues/closeIssue'
import ArchiveIssueModal from '../modals/issues/archiveIssue'
import IssueComments from './issues/comments'

type ChildProps = {
  selectedLogData: any
  handleCloseDrawer: () => void
  loadProposalIssues?: () => void
}

const IssueDetailsDrawer: React.FC<ChildProps> = ({
  selectedLogData,
  handleCloseDrawer,
  loadProposalIssues,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [showCloseIssueModal, setShowCloseIssueModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const issueTabs = [
    {
      label: 'Products',
      dataTestid: 'issue-products-tab',
      content: <div>Waiting on the BE to support products</div>,
    },
    {
      label: 'Notes',
      dataTestid: 'issue-notes-tab',
      content: (
        <IssueComments selected={selectedLogData} />
      ),
    },
  ]

  const row1Data = [
    {
      label: 'Title',
      value: selectedLogData?.title,
    },
    {
      label: 'Status',
      value: <ProposalIssueStatusPresenter issue={selectedLogData} />,
    },
  ]

  const row2Data = [
    {
      label: 'Created By',
      value:
        selectedLogData?.created_by?.first_name +
        ' ' +
        selectedLogData?.created_by?.last_name,
    },
    {
      label: 'Created Date',
      value: formatDate({ date: selectedLogData?.created_at }),
    },
  ]

  const row3Data = [
    {
      label: 'Description',
      value: selectedLogData?.message,
    },
  ]

  const issueIsClosed =
    selectedLogData?.is_closed && !selectedLogData?.is_archived
  const issueIsArchived = selectedLogData?.is_archived

  return (
    <Drawer isVisible dataTestId='proposal-issue-drawer'>
      <PageHeaderComponent
        h1Text={'Inspect Issue'}
        primaryButtons={[
          {
            onClick: handleCloseDrawer,
            icon: 'Close',
            variant: 'tertiary_dark',
            dataTestid: 'close-drawer-icon',
          },
        ]}
      />
      <div className='divider-4' />
      <Box dataTestid='proposal-inspect-issue-info'>
        <GridLabelValuePair
          defaultColSize={6}
          data={row1Data}
          customClassName='mb-4'
        />
        <GridLabelValuePair
          defaultColSize={6}
          data={row2Data}
          customClassName='mb-4'
        />
        <GridLabelValuePair defaultColSize={12} data={row3Data} />
      </Box>
      <HorizontalTab
        onTabChange={setActiveTabIndex}
        selectedTab={activeTabIndex}
        data={{
          tabs: issueTabs,
        }}
      />
      <div className='drawer-footer'>
        {
          <Button
            text='Cancel'
            dataTestid='cancel-button'
            onClick={handleCloseDrawer}
            variant='secondary'
          />
        }
        {!issueIsArchived && (
          <Button
            text={issueIsClosed ? 'Archive Issue' : 'Mark Issue Closed'}
            dataTestid='issue-closed-button'
            onClick={() =>
              issueIsClosed
                ? setShowArchiveModal(true)
                : setShowCloseIssueModal(true)
            }
          />
        )}
        {/* close issue modal */}
        <CloseIssueModal
          showModal={showCloseIssueModal}
          onComplete={handleCloseDrawer}
          setShowModal={setShowCloseIssueModal}
          proposal={proposalDetails}
          issue={selectedLogData}
          loadProposalIssue={loadProposalIssues}
        />
        {/* Archive modal */}
        <ArchiveIssueModal
          showModal={showArchiveModal}
          onComplete={handleCloseDrawer}
          setShowModal={setShowArchiveModal}
          proposal={proposalDetails}
          issue={selectedLogData}
          loadProposalIssue={loadProposalIssues}
        />
      </div>
    </Drawer>
  )
}

export default IssueDetailsDrawer
