import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/singleScreenProposalSlice'
import Link from 'atoms/link/link'
import { formatDate } from 'lib/utils/date/formatDate'
import ProposalBadgeStatus from '../presenters/proposalBadgeStatus'
import { useNavigate } from 'react-router-dom'
import { formatProposalType } from 'modules/proposals/utils'
import { Box } from 'atoms'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  totalRows: number
  selected: string[]
}

const ProposalsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.selected])

  const tableColumns = [
    {
      title: 'ID',
      width: '8%',
    },
    {
      title: 'Supplier Name',
      width: '20%',
    },
    {
      title: 'Category and Type',
      width: '25%',
    },
    {
      title: '# Items',
      width: '8%',
    },
    {
      title: 'Proposed',
      width: '12%',
    },
    {
      title: 'Updated',
      width: '12%',
    },
    {
      title: 'Status',
      width: '15%',
    },
  ]

  const isSelected = (id: string) => {
    return props?.selected?.includes(id)
  }
  const path = '/proposals'

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      ({
        id,
        connection,
        department,
        proposal_type,
        item_count,
        updated_at,
        proposed_at,
        acknowledged_at,
        status,
      }) => {
        return {
          id: id,
          selected: isSelected(id),
          data: {
            'ID': {
              onRender: () => (
                <Link
                  label={id}
                  variant='primary'
                  onClick={() => navigate(`${path}/${id}`)}
                  mode='inline'
                />
              ),
            },
            'Supplier Name': { value: connection?.brand?.name },
            'Category and Type': {
              onRender: () => (
                <div>
                  <p className='body1-regular grey-1000'>
                    {department?.name ? department.name : '---'}
                  </p>
                  <p className='body2-regular grey-700'>
                    {formatProposalType(proposal_type)}
                  </p>
                </div>
              ),
            },
            '# Items': { value: item_count },
            'Proposed': { value: formatDate({ date: proposed_at }) },
            'Updated': { value: formatDate({ date: updated_at }) },
            'Status': {
              onRender: () => (
                <ProposalBadgeStatus
                  status={status}
                  acknowledgedAt={acknowledged_at}
                />
              ),
            },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <Box dataTestid='proposals-list-table'>
      <Table
        dataTestidPrefix='proposals-'
        columns={tableColumns}
        data={tableData}
        loading={props.loading}
        customPaginationProps={{
          handlePagination: (pageNumber: number) => {
            setPage(pageNumber)
          },
          activePageNumber: props.currentPage,
          perPage: 10,
          totalRecords: props.totalRows,
        }}
      />
    </Box>
  )
}

export default ProposalsTable
