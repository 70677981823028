import { FC, useEffect, useMemo, useState } from 'react'
import VariantItem from 'molecules/lineItem/variantItem'
import { Box, Link, Table } from 'atoms'
import { getProposalVariants } from 'api/proposals'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import DrawerItemAttributes from './itemAttributes'
import { useParams } from 'react-router-dom'

type ChildProps = {
  selected: any
}

const DrawerItemsTable: FC<ChildProps> = ({ selected }) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [productVariants, setProductVariants] = useState([])
  const [showVariantAttributes, setShowVariantAttributes] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const params = useSelector(
    (state: RootState) =>
      state.singleScreenProposal.proposedProductVariantParams
  )
  const tableColumns = [
    {
      title: 'Item',
      width: '65%',
    },
    {
      title: 'Supplier SKU',
      width: '35%',
    },
  ]

  const fetchProductVariants = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getProposalVariants({
      id: id || 10550,
      params: {
        product_id: selected.id,
        ...params,
      },
    })
    setProductVariants(data.results)
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await fetchProductVariants()
    })()
  }, [selected])

  // Function to render VariantItem
  const renderVariantItem = (item) =>
    VariantItem({
      variant: item,
      onClick: () => handleVariantClick(item),
    })

  const handleVariantClick = (item) => {
    setShowVariantAttributes(true)
    setSelectedItem(item)
  }

  const tableData = useMemo(() => {
    return productVariants.map((item) => {
      return {
        id: item?.id,
        data: {
          'Item': {
            onRender: () => renderVariantItem(item),
          },
          'Supplier SKU': {
            value: item?.brand_identifier ? item?.brand_identifier : '--',
          },
        },
      }
    })
  }, [productVariants])

  // @shubham - should we do pagination here??
  return (
    <Box dataTestid='variants-table'>
      {!selectedItem && !showVariantAttributes ? (
        <Table
          columns={tableColumns}
          data={tableData}
          showPagination
          hideCaption={false}
          loading={loading}
        />
      ) : (
        <Box dataTestid='variants-table-item-attributes'>
          <div className='h5 mt-3 mb-3 space-between'>
            <span>{selectedItem?.name}</span>
            <Link
              dataTestid='close-link'
              label='Close'
              onClick={() => {
                setShowVariantAttributes(false)
                setSelectedItem(null)
              }}
            />
          </div>
          <DrawerItemAttributes selected={selectedItem} />
        </Box>
      )}
    </Box>
  )
}

export default DrawerItemsTable
