import { createSlice } from '@reduxjs/toolkit'

const createGenericSlice = (sliceName, initialState, extraReducers = {}) => {
  return createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setSorting(state, action) {
        state.params.order_by = action.payload
      },
      setParams(state, action) {
        state.params = action.payload
      },
      setSearch(state, action) {
        if (action?.payload) {
          state.params['payload'] = action.payload
        } else {
          delete state.params['payload']
        }
        state.params.page = 1
        state.params.order_by = initialState.params.order_by
      },
      setFilters(state, action) {
        state.params.page = 1
        if (!action.payload.value) {
          delete state.params[action.payload.filter]
        } else {
          state.params[action.payload?.filter] = action.payload?.value
        }
      },
      setCurrentPage(state, action) {
        const { page, target } = action.payload
        if (target) {
          state[target].page = page
        } else {
          state.params.page = action.payload
        }
      },
      ...extraReducers, // Allow passing custom reducers
    },
  })
}

export default createGenericSlice
