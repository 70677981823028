import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/singleScreenProposalSlice'
import { formatDate } from 'lib/utils/date/formatDate'
import ProposalBadgeStatus from '../presenters/proposalBadgeStatus'
import { formatProposalType } from 'modules/proposals/utils'
import { handleAllRowSelect, handleSingleRowSelect } from 'lib/utils/table'
// using the proposal products slice here to setSelected
import { setSelected } from 'store/proposalProductsSlice'
import VariantItem from 'molecules/lineItem/variantItem'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  totalRows: number
  handleOpenDrawer: (id: string) => void
  selectedLogForSideDrawer: any
  selectedProducts: string[]
}

const ProposedProductsTable: FC<ChildProps> = ({
  data,
  loading,
  currentPage,
  totalRows,
  handleOpenDrawer,
  selectedLogForSideDrawer,
  selectedProducts,
}) => {
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: data })
  }, [!loading, selectedProducts])

  const tableColumns = [
    {
      title: 'Product',
      width: '25%',
    },
    {
      title: 'Proposal',
      width: '10%',
    },
    {
      title: 'Supplier',
      width: '20%',
    },
    {
      title: 'Category and Type',
      width: '18%',
    },
    {
      title: 'Proposed',
      width: '12%',
    },
    {
      title: 'Status',
      width: '15%',
    },
  ]

  const parsedTableColumns = useMemo(() => {
    if (selectedLogForSideDrawer?.id) {
      return tableColumns.filter(
        (col) =>
          col.title !== 'Supplier' &&
          col.title !== 'Category and Type' &&
          col.title !== 'Proposed'
      )
    }
  }, [selectedLogForSideDrawer])

  // Function to render product
  const renderProduct = (product) =>
    VariantItem({
      variant: product,
      onClick: () => handleOpenDrawer(product.id),
      isProduct: true,
    })

  const renderCategoryColumn = (product) => (
    <div>
      <p className='body1-regular grey-1000'>
        {product.department?.length ? product.department[0].name : '---'}
      </p>
      <p className='body2-regular grey-700'>
        {product?.proposal_status
          ? formatProposalType(product?.proposal_status)
          : '--'}
      </p>
    </div>
  )

  const isSelected = (id: string) => {
    return selectedProducts?.includes(id)
  }

  // status and proposed date needs values
  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((product) => {
      return {
        id: product.id,
        selected: isSelected(product?.id),
        data: {
          'Product': {
            onRender: () => renderProduct(product),
          },
          'Proposal': { value: '10524' },
          'Supplier': { value: product?.brand?.name },
          'Category and Type': {
            onRender: () => renderCategoryColumn(product),
          },
          'Proposed': { value: formatDate({ date: product?.created_at }) },
          'Status': {
            onRender: () => (
              <ProposalBadgeStatus status={''} acknowledgedAt={null} />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(
      setCurrentPage({
        target: 'proposedProductsParams',
        page,
      })
    )
  }

  return (
    <Table
      dataTestidPrefix='proposal-proposed-products-'
      columns={selectedLogForSideDrawer?.id ? parsedTableColumns : tableColumns}
      data={tableData}
      loading={loading}
      selectable
      onRowSelect={(e, rowDetails, selected) =>
        handleSingleRowSelect(
          e,
          rowDetails,
          selected,
          selectedProducts,
          setSelected
        )
      }
      onAllRowSelect={(e, allRowsSelected) =>
        handleAllRowSelect(e, allRowsSelected, setSelected, tableData)
      }
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setPage(pageNumber)
        },
        activePageNumber: currentPage,
        perPage: 10,
        totalRecords: totalRows,
      }}
    />
  )
}

export default ProposedProductsTable
