import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Box } from 'atoms'
import ProposedProductsTable from '../components/list/proposalProductsTable'
import BulkActionsBar from 'organisms/bulkActions'
import { PAGE_TYPE } from 'lib/constants'
import SearchBar from 'molecules/search/searchBarTable'
import { setSearch } from 'store/singleScreenProposalSlice'

type ChildProps = {
  proposalProducts: any
  loading: boolean
  handleOpenDrawer: (id: string) => void
  selectedLogForSideDrawer: any
  reloadProposalProducts: (quiet?: boolean) => void
}

const ProposedProducts: React.FC<ChildProps> = ({
  proposalProducts,
  loading,
  handleOpenDrawer,
  selectedLogForSideDrawer,
  reloadProposalProducts
}) => {

  const params = useSelector(
    (state: RootState) => state.singleScreenProposal.proposedProductsParams
  )
  
  // using the proposal products slice here
  const selected = useSelector(
    (state: RootState) => state.proposalProducts.selected
  )

  return (
    <Box width='100%'>
      <SearchBar
        placeholder='Search by Product name'
        setSearch={setSearch}
        className='mt-4'
        dataTestid='proposal-proposed-products-search-bar'
        target='proposedProductsParams'
      />
      <BulkActionsBar
        loading={loading}
        hasBulkActions
        selectedItems={selected}
        totalRows={proposalProducts?.count}
        currentPage={params.page}
        pageType={PAGE_TYPE.PROPOSAL_PRODUCTS}
        loadProducts={reloadProposalProducts}
      />
      <ProposedProductsTable
        data={proposalProducts?.results}
        loading={loading}
        totalRows={proposalProducts?.count}
        currentPage={params?.page}
        handleOpenDrawer={(id: string) => handleOpenDrawer(id)}
        selectedLogForSideDrawer={selectedLogForSideDrawer}
        selectedProducts={selected}
      />
    </Box>
  )
}

export default ProposedProducts
