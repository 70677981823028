import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import ProposalIssueStatusPresenter from '../presenters/issueStatus'
import { Table, Link } from 'atoms'

type ChildProps = {
  data: any
  loading: boolean
  handleOpenDrawer: (id: string) => void
}

const ProposalIssuesTable: FC<ChildProps> = (props) => {
  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    if (!props.loading) {
      formatSetTableData({ data: props.data })
    }
  }, [props.loading, props.data])

  const tableColumns = [
    {
      title: 'Title',
      width: '30%',
    },
    {
      title: 'Message',
      width: '35%',
    },
    {
      title: 'Items',
      width: '10%',
    },
    {
      title: 'Notes',
      width: '10%',
    },
    {
      title: 'Status',
      width: '15%',
    },
  ]

  const renderTitleColumn = (issue) => (
    <Link
      onClick={() => props.handleOpenDrawer(issue.id)}
      label={issue.title}
      mode='inline'
    />
  )

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((issue) => {
      return {
        id: issue?.id,
        data: {
          Title: {
            onRender: () => renderTitleColumn(issue),
          },
          Message: { value: issue.message },
          Items: { value: issue.elements.length },
          Notes: { value: issue.memos.length },
          Status: {
            onRender: () => <ProposalIssueStatusPresenter issue={issue} />,
          },
        },
      }
    })
    setTableData(formattedData)
  }

  return (
    <>
      <Table columns={tableColumns} data={tableData} loading={props.loading} />
    </>
  )
}

export default ProposalIssuesTable
