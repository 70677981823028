import React, { useMemo, useState } from 'react'
import { Drawer } from '@teamfabric/copilot-ui'
import { Button, HorizontalTab } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import MoreActionButtonChildren from 'molecules/button/moreDropdownChildren'
import DrawerItemsTable from './variantsTable'
import DrawerItemAttributes from './itemAttributes'
import DrawerProductAttributes from './productAttributes'
import RemoveProposalProductModal from '../modals/removeProposalProduct'
import CreateIssueModal from '../modals/createIssue'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import AddToOpenIssueModal from '../modals/addToOpenIssue'

type ChildProps = {
  selectedLogData: any
  handleCloseDrawer: () => void
  type?: 'product' | 'variant'
  fetchProposalProposedProducts?: () => void
  loadProposal?: () => void
}

const ProposalProductDrawer: React.FC<ChildProps> = ({
  selectedLogData,
  handleCloseDrawer,
  fetchProposalProposedProducts,
  loadProposal,
  type,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [showRemoveProductsModal, setShowRemoveProductsModal] = useState(false)
  const [showCreateIssue, setShowCreateIssue] = useState(false)
  const [showAddToOpenIssue, setShowAddToOpenIssue] = useState(false)

  const variantTabs = [
    {
      label: 'Attributes',
      dataTestid: 'item-attributes-tab',
      content: <DrawerItemAttributes selected={selectedLogData} />,
    },
  ]
  const productTabs = [
    {
      label: 'Attributes',
      dataTestid: 'product-attributes-tab',
      content: <DrawerProductAttributes selected={selectedLogData} />,
    },
    {
      label: 'Variants',
      dataTestid: 'product-variants-tab',
      content: <DrawerItemsTable selected={selectedLogData} />,
    },
  ]

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const canAddToOpenIssue = useMemo(() => {
    if (proposalDetails?.issues_summary.open > 0) return true
    return false
  }, [proposalDetails?.issues_summary])

  const MoreActions = [
    {
      name: 'Add To Open Issue',
      handleClick: () => setShowAddToOpenIssue(true),
      isDisabled: !canAddToOpenIssue,
    },
    {
      name: 'Create Issue',
      handleClick: () => setShowCreateIssue(true),
    },
  ]

  return (
    <Drawer isVisible dataTestId='proposal-product-drawer'>
      <PageHeaderComponent
        h1Text={selectedLogData?.parent_name || selectedLogData?.name}
        h2Text={type === 'product' ? 'Proposal: 11530' : ''}
        badgeProps={type === 'product' && { label: 'Pending', status: 'alert' }}
        primaryButtons={[
          {
            onClick: handleCloseDrawer,
            icon: 'Close',
            variant: 'tertiary_dark',
            dataTestid: 'close-drawer-icon',
          },
        ]}
      />
      <div className='divider-4' />
      <HorizontalTab
        onTabChange={setActiveTabIndex}
        selectedTab={activeTabIndex}
        data={{
          tabs: type === 'product' ? productTabs : variantTabs,
        }}
      />
      {type === 'product' && (
        <div className='drawer-footer'>
          <Button
            text='More'
            icon='ArrowDown'
            variant='secondary'
            dataTestid='more-options-button'
            onClick={() => null}
            popoverProps={{
              children: <MoreActionButtonChildren actions={MoreActions} />,
              placement: 'bottom-start',
            }}
          />
          <Button
            text='Decline'
            dataTestid='decline-button'
            onClick={() => setShowRemoveProductsModal(true)}
            variant='secondary'
          />
          <Button
            text='Approve'
            dataTestid='approve-button'
            onClick={() => null}
          />
        </div>
      )}
      <RemoveProposalProductModal
        showModal={showRemoveProductsModal}
        setShowModal={setShowRemoveProductsModal}
        loadProducts={fetchProposalProposedProducts}
        product={selectedLogData}
      />
      {/* Create new issue modal - to do: modify for products */}
      <CreateIssueModal
        count={1}
        itemIdArray={[selectedLogData?.proposal_variant?.id]}
        showModal={showCreateIssue}
        setShowModal={setShowCreateIssue}
        loadProposal={loadProposal}
      />
      {/* Add to open issue modal - to do: modify for products*/}
      <AddToOpenIssueModal
        count={1}
        itemIdArray={[selectedLogData?.proposal_variant?.id]}
        showModal={showAddToOpenIssue}
        setShowModal={setShowAddToOpenIssue}
        loadProposal={loadProposal}
      />
    </Drawer>
  )
}

export default ProposalProductDrawer
