import { FC, useState } from 'react'
import { Box, Button, MultiLineTextArea } from 'atoms'
import { getProposalIssueDetails } from 'api/proposals'
import { useParams } from 'react-router-dom'
import { postIssueMemo } from 'api/memo'
import { useToast } from '@teamfabric/copilot-ui'
import PostedComment from 'molecules/comments/postedComment'

type ChildProps = {
  selected: any
}

const IssueComments: FC<ChildProps> = ({ selected }) => {
  const { id } = useParams()
  const [memoText, setMemoText] = useState(undefined)
  const [saving, setSaving] = useState(false)
  const [memos, setMemos] = useState(selected.memos)

  const showToast = useToast()

  const fetchIssueDetails = async () => {
    const { data } = await getProposalIssueDetails({
      proposalId: id,
      issueId: selected?.id,
    })
    setMemos(data.memos)
  }

  const addMemo = async () => {
    try {
      setSaving(true)
      const { data } = await postIssueMemo({
        text: memoText,
        module: 'proposals',
        moduleId: id,
        issueId: selected.id,
      })
      if (data?.id) {
        fetchIssueDetails()
      }
      showToast({
        label: 'Note added successfully!',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error?.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error adding the note: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
      setMemoText('')
    }
  }

  return (
    <Box
      dataTestid='issues-comments-details'
      border={{ radius: 1, width: '1px', color: '#E7E7E7' }}
      margin={{ top: 4 }}
      padding={{ left: 4, right: 4, top: 4, bottom: 4 }}
    >
      <p className='body2-regular grey-600 mb-2'>
        Issue notes can be seen by all parties
      </p>
      <div className='space-between gap-4 mb-5'>
        <MultiLineTextArea
          label=''
          width='100%'
          inputProps={{
            value: memoText,
            onChange: (e) => {
              setMemoText(e.target.value)
            },
            placeholder: 'Add a issue note',
            dataTestid: 'add-issue-note'
          }}
        />
        <div className='v-center'>
          <Button
            text='Post'
            onClick={() => addMemo()}
            isDisabled={!memoText}
            isLoading={saving}
            dataTestid='post-note-btn'
          />
        </div>
      </div>
      <div>
        {memos.map((memo) => (
          <PostedComment
            key={`${memo?.text}-${memo?.id}`}
            customClassName='mb-4'
            firstName={memo?.created_by?.first_name}
            lastName={memo?.created_by?.last_name}
            displayDate={memo?.created_at}
            text={memo?.text}
            dataTestId={`${memo?.text}-${memo?.id}`}
          />
        ))}
      </div>
    </Box>
  )
}

export default IssueComments
