import React, { useEffect, useState } from 'react'
import { getProposalSummary } from 'api/proposals'
import { keyBy } from 'lodash'
import SelectableCard from 'atoms/card/selectableCard'
import { Box, GridRow, GridCol } from 'atoms'
import { setCardPreFilters } from 'store/singleScreenProposalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

type ChildProps = {
  proposedProductsCount: string
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>
}

const ProposalSelectableCards: React.FC<ChildProps> = ({
  proposedProductsCount,
  setActiveTabIndex,
}) => {
  const [loading, setLoading] = useState(true)
  const [allProposalsCount, setAllProposalsCount] = useState(0)
  const [proposedCount, setProposedCount] = useState(0)
  const [publishingCount, setPublishingCount] = useState(0)
  const [revisedCount, setRevisedCount] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)
  const [selectedPreset, setSelectedPreset] = useState('all_proposals')
  const dispatch = useDispatch()

  const params = useSelector(
    (state: RootState) => state.singleScreenProposal.params
  )

  const fetchProposalSummary = async () => {
    const { data } = await getProposalSummary({})
    const statuses = keyBy(data, (o) => o.status)
    let proposedCount = 0
    let revisedCount = 0
    let completed_unacknowledgedCount = 0
    let completed_acknowledgedCount = 0
    const totalCount = data.reduce((sum, item) => sum + item.count, 0)
    for (const key in statuses) {
      const { status, count } = statuses[key]
      switch (status) {
        case 'proposed':
          proposedCount += count
          break
        case 'approved':
          proposedCount += count
          break
        case 'pricing_approved':
          proposedCount += count
          break
        case 'revised':
          revisedCount += count
          break
        case 'completed_unacknowledged':
          completed_unacknowledgedCount += count
          break
        case 'completed_acknowledged':
          completed_acknowledgedCount += count
          break
      }
    }
    setAllProposalsCount(totalCount)
    setProposedCount(proposedCount)
    setRevisedCount(revisedCount)
    setPublishingCount(completed_unacknowledgedCount)
    setCompletedCount(completed_acknowledgedCount)
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await fetchProposalSummary()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (params?.status === 'completed' && params?.is_acknowledged === 0) {
      setSelectedPreset('publishing')
    } else if (
      params?.status === 'completed' &&
      params?.is_acknowledged === 1
    ) {
      setSelectedPreset('completed')
    } else if (!params.status) {
      setSelectedPreset('all_proposals')
    } else {
      setSelectedPreset(params?.status)
    }
  }, [params])

  const setStatusOnCardClick = ({ key }) => {
    if (key === 'all_products') {
      setActiveTabIndex(1)
    } else {
      setActiveTabIndex(0)
      dispatch(setCardPreFilters({ preFilterKey: key }))
    }
    setSelectedPreset(key)
  }

  const firstRowCards = [
    {
      label: 'All Proposals',
      key: 'all_proposals',
      description: 'All proposal states',
      value: allProposalsCount,
    },
    {
      label: 'Proposed Products',
      key: 'all_products',
      description: 'Products in proposals awaiting approval',
      value: proposedProductsCount,
    },
  ]

  const secondRowCards = [
    {
      label: 'New Proposals',
      key: 'proposed,approved,pricing_approved',
      description: 'Proposals awaiting approval',
      value: proposedCount,
    },
    {
      label: 'Returned Proposals',
      key: 'revised',
      description: 'Proposals returned to suppliers',
      value: revisedCount,
    },
    {
      label: 'Publishing Proposals',
      key: 'publishing',
      description: 'Proposals awaiting publishing',
      value: publishingCount,
    },
    {
      label: 'Completed Proposals',
      key: 'completed',
      description: 'Completed proposals all steps',
      value: completedCount,
      handleClick: () => {
        console.log('items passed detail')
      },
    },
  ]

  return (
    <Box width='100%'>
      <GridRow padding={false}>
        {firstRowCards.map((el) => {
          return (
            <GridCol lg={6} md={6} sm={12} key={el.key}>
              <SelectableCard
                text={el.value.toString()}
                description={`${el.label}`}
                onChange={() => setStatusOnCardClick({ key: el.key })}
                orientation='vertical'
                width='100%'
                maxWidth='100%'
                selected={el.key === selectedPreset}
                disabled={loading}
                dataTestid={`${el.key}-selectable-card`}
              />
            </GridCol>
          )
        })}
      </GridRow>
      <div className='divider-4' />
      <GridRow padding={false}>
        {secondRowCards.map((el) => {
          return (
            <GridCol lg={3} md={3} sm={6} key={el.key}>
              <SelectableCard
                text={el.value.toString()}
                description={`${el.label}`}
                onChange={() => setStatusOnCardClick({ key: el.key })}
                orientation='vertical'
                width='100%'
                maxWidth='100%'
                selected={el.key === selectedPreset}
                disabled={loading}
                dataTestid={`${el.key}-selectable-card`}
              />
            </GridCol>
          )
        })}
      </GridRow>
    </Box>
  )
}

export default ProposalSelectableCards
