import React, { useEffect, useMemo, useState } from 'react'
import { PRODUCTS } from 'modules/constants/products'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxWithSearchFilter from 'molecules/filter/singleFilter/checkboxWithSearch'
import {
  handleDateFilterChange,
  handleCheckboxFilterChange,
  handleCheckboxWithSearchFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import {
  setFilters,
  setConnectionOptions,
  setStatusOptions,
  setProposalTypeOptions,
  setProposedOn,
  resetAllFilters,
} from 'store/singleScreenProposalSlice'
// 'store/proposalsSlice'
// singleScreenProposalSlice
import Box from 'atoms/box/box'
import { FILTERS } from 'molecules/filter/constants'
import FilterChips from 'organisms/filterChips'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import AllFiltersModal from 'organisms/allFiltersModal/proposals'
import { PROPOSALS } from 'modules/constants/proposals'

type ChildProps = {
  params: any
}

const ProposalFilters: React.FC<ChildProps> = ({ params }) => {
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [statusCounter, setStatusCounter] = useState(0)
  const [proposalTypeCounter, setProposalTypeCounter] = useState(0)
  const [proposedOnCounter, setProposedOnCounter] = useState(0)
  const [moreFiltersCounter, setMoreFiltersCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.singleScreenProposal.connectionOptions
  )
  const statusCheckboxOptions = useSelector(
    (state: RootState) => state.singleScreenProposal.statusOptions
  )
  const proposalTypeCheckboxOptions = useSelector(
    (state: RootState) => state.singleScreenProposal.proposalTypeOptions
  )
  const proposedOn = useSelector(
    (state: RootState) => state.singleScreenProposal.proposedOn
  )

  const createdOn = useSelector((state: RootState) => state.singleScreenProposal.createdOn)

  const isAFilterApplied = useMemo(() => {
    return (
      statusCounter +
        proposalTypeCounter +
        proposedOnCounter +
        moreFiltersCounter +
        connectionsCounter >
      0
    )
  }, [
    statusCounter,
    proposalTypeCounter,
    proposedOnCounter,
    connectionsCounter,
    moreFiltersCounter
  ])

  // Define respective filterKey fns
  const filterActions = {
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: PRODUCTS.CONNECTIONS.NAME,
    },
    status: {
      options: statusCheckboxOptions,
      action: setStatusOptions,
      setFilterCount: setStatusCounter,
      filterType: FILTERS.TYPES.CHECKBOX,
      chipName: PROPOSALS.STATUS.NAME,
    },
    proposal_type: {
      options: proposalTypeCheckboxOptions,
      action: setProposalTypeOptions,
      setFilterCount: setProposalTypeCounter,
      filterType: FILTERS.TYPES.CHECKBOX,
      chipName: PROPOSALS.TYPE.NAME_SHORT,
    },
    proposed_at_gte: {
      setFilterCount: setProposedOnCounter,
      dateRange: proposedOn,
      action: setProposedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCTS.PROPOSED_ON.NAME,
    },
    is_acknowledged: {
      filterType: FILTERS.TYPES.RADIO,
      setFilterCount: setMoreFiltersCounter,
      label: PRODUCTS.ACKNOWLEDGED.NAME + '?',
      chipName: PRODUCTS.ACKNOWLEDGED.NAME,
    },
    created_at_gte: {
      dateRange: createdOn,
      setFilterCount: setMoreFiltersCounter,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCTS.CREATED_ON.NAME,
    },
  }

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxWithSearchFilter
          dataTestid={`${PRODUCTS.CONNECTIONS.NAME}-filter`}
          label={PRODUCTS.CONNECTIONS.NAME}
          onChange={(id) =>
            handleCheckboxWithSearchFilterChange({
              id,
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
            })
          }
          onClear={() =>
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              isReset: true,
            })
          }
          onOptionClick={(selectedOption) => {
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
              selectedOption,
              isHandleAdd: true,
            })
          }}
          checkboxTree={connectionCheckboxOptions}
          loading={false}
          filterCount={
            connectionsCounter > 0 ? connectionsCounter.toString() : ''
          }
        />
        <CheckboxFilter
          dataTestid={`${PROPOSALS.TYPE.NAME_SHORT}-filter`}
          label={PROPOSALS.TYPE.NAME_SHORT}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: PROPOSALS.TYPE.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: PROPOSALS.TYPE.PARAM,
              setFilters,
              isReset: true,
            })
          }
          data={proposalTypeCheckboxOptions}
          initialSelectedOptions={proposalTypeCounter}
        />
        <CheckboxFilter
          dataTestid={`${PROPOSALS.STATUS.NAME}-filter`}
          label={PROPOSALS.STATUS.NAME}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: PROPOSALS.STATUS.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: PROPOSALS.STATUS.PARAM,
              setFilters,
              isReset: true,
            })
          }
          data={statusCheckboxOptions}
          initialSelectedOptions={statusCounter}
        />
        <DateFilter
          dataTestid={`${PRODUCTS.PROPOSED_ON.NAME}-filter`}
          label={PRODUCTS.PROPOSED_ON.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.PROPOSED_ON.PARAM_START,
              date,
            })
          }}
          defaultValue={proposedOn}
          filterCount={proposedOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.PROPOSED_ON.PARAM_START,
              isReset: true,
            })
          }
        />
        <AllFiltersModal params={params} setMoreFiltersCounter={setMoreFiltersCounter}/>
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default ProposalFilters
