// old proposal screen
// import ProposalListingTemplate from 'modules/proposals/list'
// proposal single screen
import ProposalListTemplate from 'modules/proposals/list/newIndex'

const ProposalsList = () => {
  return <ProposalListTemplate />
  // return <ProposalListingTemplate />
}

export default ProposalsList
