import { useState } from 'react'

const useManageSideDrawer = ({ eventLogsData }) => {
  const [selectedLogForSideDrawer, setSelectedLogForSideDrawer] = useState(null)

  const handleOpenDrawer = (id: string) => {
    let selectedLog
    // Try to find the log by id in the parent eventLog
    selectedLog = eventLogsData.find((eventLog) => eventLog.id === id)
    
    // If not found in parent, check if there are variants and find it there
    // if (!selectedLog) {
    //   eventLogsData.some((eventLog) => {
    //     if (eventLog.num_variants && eventLog.variants) {
    //       const variant = eventLog.variants.find((el) => el.id === id)
    //       if (variant) {
    //         selectedLog = variant
    //         return true // Exit the loop once we find the variant
    //       }
    //     }
    //     return false
    //   })
    // }

    // Set the selected log or variant to state
    setSelectedLogForSideDrawer(selectedLog)
  }

  const handleCloseDrawer = () => {
    setSelectedLogForSideDrawer(null)
  }

  return { selectedLogForSideDrawer, handleOpenDrawer, handleCloseDrawer }
}

export { useManageSideDrawer }
