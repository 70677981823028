import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { removeProposalProduct, removeProposalProducts } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import Banner from 'atoms/banner/banner'
import { useToast } from '@teamfabric/copilot-ui'
import Dropdown from 'atoms/dropdown/dropdown'
import { MODALS } from 'molecules/modals/constants'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  product?: any
  loadProducts: (quiet?: boolean) => void
}

const RemoveProposalProductModal: React.FC<ChildProps> = (props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState({ id: null, label: null })
  const { id, productId } = useParams()
  const showToast = useToast()

  const stateSelected = useSelector(
    (state: RootState) => state.proposalProducts.selected
  )

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const onRemove = async () => {
    setLoading(true)
    try {
      if (stateSelected.length > 0) {
        await removeProducts()
      } else if (props.product) {
        await removeProduct()
      }
    } catch (error) {
      showToast({
        label: `Error removing the product: ${error?.response?.data?.detail}`,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      if (productId && productId !== undefined) {
        navigate(`/proposals/${id}`)
      } else {
        props.loadProducts()
      }
      props.setShowModal(false)
      setLoading(false)
    }
  }

  const removeProducts = async () => {
    const json = []
    stateSelected.forEach((productId) => {
      if (proposalDetails?.status === 'draft') {
        json.push({ product: { id: productId }, delete: 1 })
      } else {
        json.push({ product: { id: productId }, reject_reason: reason.id })
      }
    })
    // hardcoded proposal id for testing, waiting for BE changes
    await removeProposalProducts({
      id: id || 10498,
      body: json,
    })
    showToast({
      label: `Products, has been removed successfully.`,
      isDismissable: true,
      id: '1',
    })
  }

  const removeProduct = async () => {
    await removeProposalProduct({
      id: id,
      productId: props.product?.id,
      body: {
        reject_reason: reason.id,
      },
    })
    showToast({
      label: `Your product, "${props.product?.parent_name}", has been removed successfully.`,
      isDismissable: true,
      id: '1',
    })
  }

  const closeModal = () => {
    props.setShowModal(false)
    setReason({ id: null, label: null })
  }

  return (
    <>
      <Modal
        headerText='Remove product'
        onClose={() => closeModal()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => closeModal(),
            text: "No, Don't Remove",
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => onRemove(),
            text: 'Yes, Remove Product',
            variant: VARIANTS.DESTRUCTIVE,
            icon: 'Trash',
            isDisabled: loading || !reason.id,
          },
        ]}
        onBackdropClick={() => closeModal()}
      >
        <>
          <p className='body1_regular grey-600 mb-4'>
            Are you sure you want to remove the product?
          </p>
          <Banner
            label='If you remove this product from this proposal, it cannot be restored (and will have to be added in a different list). Are you sure you want to remove this product?'
            variant='error'
          />
          <div className='mt-4'>
            <Dropdown
              dataTestid='remove-product-dropdown'
              disabled={loading}
              options={MODALS.REMOVE_PROPOSAL_ITEMS.REASON_DROPDOWN.OPTIONS}
              placeholder={
                MODALS.REMOVE_PROPOSAL_ITEMS.REASON_DROPDOWN.PLACEHOLDER
              }
              label='Reason'
              value={reason}
              width='100%'
              onChange={(e: any) => {
                setReason(e)
              }}
            />
          </div>
        </>
      </Modal>
    </>
  )
}

export default RemoveProposalProductModal
