import { combineReducers } from 'redux'
import contextSlice from './contextSlice'
import invoicesSlice from './invoicesSlice'
import cancellationsSlice from './cancellationsSlice'
import orderSlice from './orderSlice'
import productsSlice from './productsSlice'
import permitsSlice from './permitsSlice'
import userSlice from './userSlice'
import onboardingSlice from './onboardingSlice'
import proposalsSlice from './proposalsSlice'
import proposalProductsSlice from './proposalProductsSlice'
import proposalProductItemsSlice from './proposalProductItemsSlice'
import proposalItemsSlice from './proposalItemsSlice'
import merchantSlice from './merchantSlice'
import supplierSlice from './suppliersSlice'
import shipmentsSlice from './shipmentsSlice'
import returnsSlice from './returnsSlice'
import feedRequestSlice from './feedRequestSlice'
import inventorySummarySlice from './inventorySummarySlice'
import proposalDepartmentsSlice from './proposalDepartmentsSlice'
import productInventoryTemplatesSlice from './productInventoryTemplatesSlice'
import productRulesetsSlice from './productRulesetsSlice'
import shopifySlice from './shopifySlice'
import jobsSlice from './jobsSlice'
import externalPlatformTemplatesSlice from './externalPlatformTemplates'

const rootReducer = combineReducers({
  orders: orderSlice,
  invoices: invoicesSlice,
  cancellations: cancellationsSlice,
  user: userSlice,
  context: contextSlice,
  products: productsSlice,
  permits: permitsSlice,
  onboarding: onboardingSlice,
  merchant: merchantSlice,
  proposals: proposalsSlice,
  proposalProducts: proposalProductsSlice,
  proposalProductItems: proposalProductItemsSlice,
  proposalItems: proposalItemsSlice,
  suppliers: supplierSlice,
  shipments: shipmentsSlice,
  returns: returnsSlice,
  feedRequest: feedRequestSlice,
  inventorySummary: inventorySummarySlice,
  proposalDepartments: proposalDepartmentsSlice,
  productInventoryTemplates: productInventoryTemplatesSlice,
  productRulesets: productRulesetsSlice,
  shopify: shopifySlice,
  jobs: jobsSlice,
  externalPlatformTemplates: externalPlatformTemplatesSlice
})

export default rootReducer
