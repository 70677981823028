import React, { useEffect, useState } from 'react'
import { Box, HorizontalTab } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import ProposalSelectableCards from '../components/list/selectableCards'
import Proposals from './proposalsList'
import ProposedProducts from './proposedProducts'
import { useManageSideDrawer } from 'modules/proposals/utils/drawer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { getProposalProposedProducts } from 'api/proposals'
import ProposalProductDrawer from '../components/drawer'
import { setSelected } from 'store/proposalProductsSlice'

const ProposalListTemplate: React.FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [proposalProducts, setProposalProducts] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () => window.updateBreadcrumb(breadcrumbs.supplierProposals()),
      0
    )
  }, [])

  const params = useSelector(
    (state: RootState) => state.singleScreenProposal.proposedProductsParams
  )

  useEffect(() => {
    if (params) {
      ;(async () => {
        await fetchProposalProposedProducts()
      })()
    }
  }, [params])

  const fetchProposalProposedProducts = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    // using the proposal products slice here
    dispatch(setSelected([]))
    const { data } = await getProposalProposedProducts({
      params: { ...params },
    })
    setProposalProducts(data)
    setLoading(false)
  }

  const { selectedLogForSideDrawer, handleOpenDrawer, handleCloseDrawer } =
    useManageSideDrawer({ eventLogsData: proposalProducts?.results })

  return (
    <Box>
      <Box width='100%' padding={[6]}>
        <PageHeaderComponent
          dataTestid='proposals-header'
          h1Text='Proposals'
          customClassName='mb-4'
        />
        <ProposalSelectableCards
          proposedProductsCount={loading ? '0' : proposalProducts?.count}
          setActiveTabIndex={setActiveTabIndex}
        />
        <div className='divider-4' />
        <HorizontalTab
          onTabChange={setActiveTabIndex}
          selectedTab={activeTabIndex}
          data={{
            tabs: [
              {
                label: 'Proposals',
                content: <Proposals />,
                dataTestid: 'proposals-tab',
              },
              {
                label: 'Proposed Products',
                isDisabled: loading,
                dataTestid: 'proposal-products-tab',
                content: (
                  <ProposedProducts
                    proposalProducts={proposalProducts}
                    loading={loading}
                    handleOpenDrawer={(id) => handleOpenDrawer(id)}
                    selectedLogForSideDrawer={selectedLogForSideDrawer}
                    reloadProposalProducts={fetchProposalProposedProducts}
                  />
                ),
              },
            ],
          }}
        />
      </Box>
      <Box>
        {selectedLogForSideDrawer && (
          <ProposalProductDrawer
            selectedLogData={selectedLogForSideDrawer}
            handleCloseDrawer={handleCloseDrawer}
            type='product'
            fetchProposalProposedProducts={fetchProposalProposedProducts}
          />
        )}
      </Box>
    </Box>
  )
}

export default ProposalListTemplate
