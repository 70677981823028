import React, { useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Box } from 'atoms'
import { getProposals } from 'api/proposals'
import ProposalsTable from '../components/table/list'
import BulkActionsBar from 'organisms/bulkActions'
import { PAGE_TYPE } from 'lib/constants'
import SearchBar from 'molecules/search/searchBarTable'
import { setSearch } from 'store/singleScreenProposalSlice'
import ProposalFilters from '../components/filters/list'

const Proposals: React.FC = () => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  const params = useSelector(
    (state: RootState) => state.singleScreenProposal.params
  )

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const { data } = await getProposals({ params: { ...params } })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  return (
    <Box width='100%'>
      <SearchBar
        placeholder='Search by Proposal name'
        setSearch={setSearch}
        className='mt-4'
        dataTestid='proposals-search-bar'
      />
      <div className='mt-3 mb-2'>
        <ProposalFilters params={params} />
      </div>
      {/* bulk to do for proposals */}
      <BulkActionsBar
        loading={loading}
        hasBulkActions={false}
        totalRows={totalRows}
        currentPage={params.page}
        pageType={PAGE_TYPE.PROPOSALS}
      />
      <ProposalsTable
        data={tableData}
        loading={loading}
        totalRows={totalRows}
        currentPage={params?.page}
        selected={[]}
      />
    </Box>
  )
}

export default Proposals
