import axios, { AxiosInstance, AxiosResponse } from 'axios'

const env = process.env.STAGE || 'staging'

let mkpTenantDetails = JSON.parse(sessionStorage.getItem('mkp_tenant') || '{}')
let contextType = mkpTenantDetails?.type + 's'
let contextId = mkpTenantDetails?.id
let fabricTenantId = mkpTenantDetails?.fabric_tenant_id

const initializeContext = () => {
  mkpTenantDetails = JSON.parse(sessionStorage.getItem('mkp_tenant') || '{}')
  contextType = mkpTenantDetails.type + 's'
  contextId = mkpTenantDetails.id
  fabricTenantId = mkpTenantDetails.fabric_tenant_id
}

// temporary access to retailer endpoints
export const RETAILER_API_URL = Object.freeze({
  GET_CONNECTIONS: () => `v3/suppliers/connections/`,
  GET_PRODUCTS: () => `v1/${contextType}/${contextId}/variants/`,
  GET_CONNECTIONS_BY_ID: ({ id }) => `v3/suppliers/connections/${id}/`,
  ORDERS: () => `v1/${contextType}/${contextId}/orders/`,
  SHOPIFY_TEMPLATE: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/templates/`,
})

export const API_URL = Object.freeze({
  AUTHENTICATE_USER: () => 'auth/token/',
  GET_CONTEXT: () => 'v3/suppliers/',
  GET_USER: () => 'v1/users/me/',
  GET_BRANDS: () => `v1/${contextType}/`,
  ASSIGN_TASK: () => `v1/${contextType}/${contextId}/assigned-tasks/`,
  ASSIGN_TASK_DETAIL: ({ taskId }) =>
    `v1/${contextType}/${contextId}/assigned-tasks/${taskId}/`,
  RESET_ASSIGN_TASK: ({ taskId }) =>
    `v1/${contextType}/${contextId}/assigned-tasks/${taskId}/reset/`,
  COMPLETE_TASK: ({ taskId }) =>
    `v1/${contextType}/${contextId}/assigned-tasks/${taskId}/complete/`,
  GET_CONTEXT_DETAIL: () => `v1/${contextType}/${contextId}/`,
  GET_ORDERS: () => `v1/${contextType}/${contextId}/orders/`,
  GET_ORDER_BY_ID: ({ id }) => `v1/${contextType}/${contextId}/orders/${id}/`,
  GET_MERCHANTS: () => `v3/suppliers/connections/`,
  GET_CONNECTIONS: () => `/v1/${contextType}/${contextId}/connections/`,
  CONNECTIONS_DETAIL: ({ connectionId }) =>
    `/v1/${contextType}/${contextId}/connections/${connectionId}/`,
  CONNECTION_SLA: ({ connectionId, slaId }) =>
    `/v1/${contextType}/${contextId}/connections/${connectionId}/slas/${slaId}/`,
  CONNECTION_FEES: ({ connectionId, feeId }) =>
    `/v1/${contextType}/${contextId}/connections/${connectionId}/fees/${feeId}/`,
  CONNECTION_MEMO: ({ connectionId }) =>
    `/v1/${contextType}/${contextId}/connections/${connectionId}/memos/`,
  CONNECTION_PLATFORM_ACCOUNTS: ({ connectionId }) =>
    `/v1/${contextType}/${contextId}/connections/${connectionId}/platform-accounts/`,
  CONNECTIONS_COMPLETE_ONBOARDING: ({ connectionId }) =>
    `/v1/${contextType}/${contextId}/connections/${connectionId}/complete-onboarding/`,
  GET_ORDER_AGE_SUMMARY: () =>
    `v1/${contextType}/${contextId}/orders-aging-summary/`,
  GET_CONNECT_BY_ID: ({ id }) => `/v3/suppliers/connections/${id}/`,
  PLATFORMS: () => `/v1/${contextType}/${contextId}/platforms/`,
  PLATFORM_ACCOUNTS: () => `/v1/${contextType}/${contextId}/platform-accounts/`,
  GET_PLATFORM_ACCOUNT_BY_ID: ({ id }) =>
    `/v1/${contextType}/${contextId}/platform-accounts/${id}/`,
  GET_SHIPMENTS: () => `/v1/${contextType}/${contextId}/shipments/`,
  GET_SHIPMENTS_DETAIL: ({ id }) =>
    `/v1/${contextType}/${contextId}/shipments/${id}/?show_shipments=1`,
  GET_SHIPMENTS_PACKING_SLIP: ({ id }) =>
    `/v1/${contextType}/${contextId}/shipments/${id}/packing_slip/`,
  EXPORT_SHIPMENTS: () =>
    `/v1/${contextType}/${contextId}/shipments/email-export/`,
  EXPORT_ORDERS: () => `/v1/${contextType}/${contextId}/orders/email-export/`,
  POST_ORDER_MEMO: ({ orderId }) =>
    `/v1/${contextType}/${contextId}/orders/${orderId}/memos/`,
  GET_RETURNS: () => `/v1/${contextType}/${contextId}/rmas/`,
  GET_RMAS_RETURNS: ({ id }) =>
    `/v1/${contextType}/${contextId}/rmas/${id}/returns/`,
  GET_CANCELS: () => `/v1/${contextType}/${contextId}/cancels/`,
  GET_CANCEL_DETAIL: ({ id }) =>
    `/v1/${contextType}/${contextId}/rmas/${id}/?short=1`,
  ACKNOWLEDGE_CANCEL: ({ id }) =>
    `/v1/${contextType}/${contextId}/cancels/${id}/acknowledge/`,
  GET_CONNECTION_LOCATION: () =>
    `/v1/${contextType}/${contextId}/connection-locations/`,
  CANCEL_DETAIL: ({ id }) => `/v1/${contextType}/${contextId}/rmas/${id}/`,
  EXPORT_CANCELS: () => `/v1/${contextType}/${contextId}/cancels/email-export/`,
  RETURN_LABEL: ({ rmaId, rmaReturnId }) =>
    `/rmas/${rmaId}/returns/${rmaReturnId}/return_label/`,
  GET_INVOICES: () => `/v1/${contextType}/${contextId}/invoices/`,
  EXPORT_INVOICES: () =>
    `/v1/${contextType}/${contextId}/invoices/export-email/`,
  ACCEPT_INVOICE: ({ id }) =>
    `/v1/${contextType}/${contextId}/invoices/${id}/acknowledge/`,
  MARK_INVOICE_PAID: ({ id }) =>
    `/v1/${contextType}/${contextId}/invoices/${id}/mark-paid/`,
  POST_ISSUE_MEMO: ({ module, moduleId, issueId }) =>
    `/v1/${contextType}/${contextId}/${module}/${moduleId}/issues/${issueId}/memos/`,
  PUT_MEMO_ACKNOWLEDGED: ({ memo_id }) =>
    `/v1/${contextType}/${contextId}/memos/${memo_id}/acknowledge/`,
  ORDER_ACTIVITY: ({ id }) =>
    `/v1/${contextType}/${contextId}/orders/${id}/activity-feed/?order_by=-id`,
  GET_INVENTORIES: () => `/v1/${contextType}/${contextId}/inventory/`,
  GET_INVENTORY: ({ id }) => `/v1/${contextType}/${contextId}/inventory/${id}/`,
  GET_INVENTORY_SUMMARY: () =>
    `/v1/${contextType}/${contextId}/inventory-summary/`,
  GET_INVENTORY_HISTORY: ({ id }) =>
    `/v1/${contextType}/${contextId}/inventory/${id}/history/?limit=30`,
  LOCATIONS: () => `/v1/${contextType}/${contextId}/locations/`,
  LOCATION_DETAIL: ({ id }) =>
    `/v1/${contextType}/${contextId}/locations/${id}/`,
  EXPORT_INVENTORY: () =>
    `/v1/${contextType}/${contextId}/inventory/export-email/`,
  GET_PRODUCTS: () => `/v1/${contextType}/${contextId}/variants/`,
  EXPORT_PRODUCTS: () => `v1/${contextType}/${contextId}/variants/export/`,
  PRODUCT_DETAIL: ({ id }) => `v1/${contextType}/${contextId}/variants/${id}/`,
  GET_PROVINCES: () => `/v1/provinces/`,
  GET_PERMITS: () => `/v1/${contextType}/${contextId}/permits/`,
  GET_PERMIT_DETAIL: ({ id }) =>
    `/v1/${contextType}/${contextId}/permits/${id}/`,
  GET_USERS: () => `/v1/${contextType}/${contextId}/users/`,
  INVITE_USER: () =>
    `/v1/${contextType}/${contextId}/users/invite/?source=fabric`,
  EXPORT_PERMITS: () => `/v1/${contextType}/${contextId}/permits/email-export/`,
  GET_PROPOSALS: () => `/v1/${contextType}/${contextId}/proposals/`,
  GET_PROPOSAL_PROPOSED_PRODUCTS: () =>
    `/v1/${contextType}/${contextId}/proposals/products/`,
  GET_PROPOSAL_DETAIL: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/`,
  PATCH_PROPOSAL: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/`,
  GET_PROPOSAL_PRODUCTS: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/products/`,
  GET_PROPOSAL_PRODUCT_DETAIL: ({ id, productId }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/products/${productId}/`,
  REMOVE_PROPOSAL_PRODUCT: ({ id, productId }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/products/${productId}/reject/`,
  REMOVE_PROPOSAL_PRODUCTS: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/products/bulk-reject/`,
  GET_PROPOSAL_VARIANTS: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/variants/`,
  GET_PROPOSAL_VARIANT_DETAIL: ({ id, variantId }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/variants/${variantId}/`,
  GET_PROPOSAL_RETURN_REASONS: () => '/v1/proposal-return-reasons/',
  PROPOSAL_APPLY_TRANSFORMER: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/apply-transformer/`,
  ACKNOWLEDGE_PROPOSAL: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/acknowledge/`,
  EXPORT_PROPOSAL: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/export/`,
  EXPORT_PROPOSAL_TO_FABRIC: ({ platformId }) =>
    `/v1/${contextType}/${contextId}/fabric/${platformId}/pim/push-bulk-products/`,
  EXPORT_PROPOSAL_PRODUCTS: ({ platformCode, platformId }) =>
    `/v1/${contextType}/${contextId}/${platformCode}/${platformId}/push-products/`,
  GET_TEMPLATES: () => `/v1/${contextType}/${contextId}/templates/`,
  GET_TEMPLATE_BY_ID: ({ id }) =>
    `/v1/${contextType}/${contextId}/templates/${id}/`,
  CLONE_TEMPLATE_BY_ID: ({ id }) =>
    `/v1/${contextType}/${contextId}/templates/${id}/clone/`,
  GET_TEMPLATE_MAPPINGS: ({ id }) =>
    `/v1/${contextType}/${contextId}/templates/${id}/mappings/`,
  GET_TEMPLATE_MAPPINGS_BY_MAPPING_ID: ({ id, mappingId }) =>
    `/v1/${contextType}/${contextId}/templates/${id}/mappings/${mappingId}/`,
  GET_PLATFORM_TEMPLATES: ({ platformCode, platformId }) =>
    `/v1/${contextType}/${contextId}/${platformCode}/${platformId}/templates/`,
  GET_PLATFORM_TEMPLATE_BY_ID: ({ platformCode, platformId, templateId }) =>
    `/v1/${contextType}/${contextId}/${platformCode}/${platformId}/templates/${templateId}/`,
  PLATFORM_TEMPLATE_MAPPINGS_BY_FIELD_TYPE: ({
    platformCode,
    platformId,
    templateId,
    fieldType,
  }) =>
    `/v1/${contextType}/${contextId}/${platformCode}/${platformId}/templates/${templateId}/${fieldType}/`,
  PLATFORM_TEMPLATE_MAPPINGS_BY_FIELD_TYPE_ID: ({
    platformCode,
    platformId,
    templateId,
    fieldType,
    fieldTypeId,
  }) =>
    `/v1/${contextType}/${contextId}/${platformCode}/${platformId}/templates/${templateId}/${fieldType}/${fieldTypeId}/`,
  ANALYZE_HEADERS: () =>
    `/v1/${contextType}/${contextId}/templates/analyze-headers/`,
  GET_TRANSFORMERS: () => `/v1/${contextType}/${contextId}/transformers/`,
  PUSH_NOTIFICATIONS: () =>
    `/v1/${contextType}/${contextId}/push-notifications/`,
  PUSH_NOTIFICATIONS_DETAIL: ({ id }) =>
    `/v1/${contextType}/${contextId}/push-notifications/${id}/`,
  NOTIFICATIONS: () => `/v1/${contextType}/${contextId}/notifications/`,
  NOTIFICATIONS_DETAIL: ({ id }) =>
    `/v1/${contextType}/${contextId}/notifications/${id}/`,
  EDI_ONBOARDING: () =>
    `/v1/${contextType}/${contextId}/edi-accounts/onboarding/`,
  EDI_CREATE_TEST_ORDER: () =>
    `/v1/${contextType}/${contextId}/edi-accounts/create-test-order/`,
  EDI_ACCOUNTS: () => `/v1/${contextType}/${contextId}/edi-accounts/`,
  FTP_ACCOUNTS: () => `/v1/${contextType}/${contextId}/ftp-accounts/`,
  ONBOARDING: () => `/v1/${contextType}/${contextId}/onboarding-tasks/`,
  CANCEL_ITEMS: () => `/v1/${contextType}/${contextId}/cancels/`,
  PROPOSAL_SUMMARY: () => `v1/${contextType}/${contextId}/proposals/summary/`,
  PROPOSAL_RULESETS_SUMMARY: ({ id }) =>
    `/v1/${contextType}/${contextId}/proposals/${id}/rulesets-summary/`,
  PROPOSAL_ISSUES: ({ proposalId }) =>
    `/v1/${contextType}/${contextId}/proposals/${proposalId}/issues/`,
  PROPOSAL_ISSUE_BY_ID: ({ proposalId, issueId }) =>
    `/v1/${contextType}/${contextId}/proposals/${proposalId}/issues/${issueId}/`,
  ARCHIVE_PROPOSAL_ISSUE: ({ proposalId, issueId }) =>
    `/v1/${contextType}/${contextId}/proposals/${proposalId}/issues/${issueId}/archive/`,
  CLOSE_PROPOSAL_ISSUE: ({ proposalId, issueId }) =>
    `/v1/${contextType}/${contextId}/proposals/${proposalId}/issues/${issueId}/close/`,
  GENERIC_SHIPPING_METHODS: () => `v1/genericshippingmethods/`,
  UPLOAD_TEMPFILE: () => '/v1/tempfiles/upload/',
  POST_FEED_QUEUE_ENQUEUE: () =>
    `/v1/${contextType}/${contextId}/feed-queue/enqueue/`,
  PUT_FEED_QUEUE_RUN: ({ feedQueueId }) =>
    `/v1/${contextType}/${contextId}/feed-queue/${feedQueueId}/run/`,
  GET_FEED_QUEUE: ({ feedQueueId }) =>
    `/v1/${contextType}/${contextId}/feed-queue/${feedQueueId}/`,
  DOWNLOAD_SAMPLE_FILE: ({ templateId }) =>
    `/v1/${contextType}/${contextId}/templates/${templateId}/mappings/?pagination=0&format=xlsx`,
  GET_INVITE_PREFERENCES: () => `v1/${contextType}/${contextId}/preferences/`,
  CONNECTION_INVITE: () => `v1/${contextType}/${contextId}/connection-invites/`,
  CONNECTION_INVITE_RESEND: ({ connectionId }) =>
    `v1/${contextType}/${contextId}/connection-invites/${connectionId}/resend/`,
  GET_COMMISSION_PROFILE: () =>
    `v1/${contextType}/${contextId}/commission-profiles/`,
  FEED_REQUESTS: () => `v1/${contextType}/${contextId}/feed-requests/`,
  FEED_REQUEST_DETAIL: ({ id }) =>
    `v1/${contextType}/${contextId}/feed-requests/${id}/`,
  TAGS: () => `v1/${contextType}/${contextId}/tags/`,
  ORDER_TAGS: ({ orderId }) =>
    `v1/${contextType}/${contextId}/orders/${orderId}/tags/`,
  TAG_ITEM: ({ tagId }) => `v1/${contextType}/${contextId}/tag-items/${tagId}/`,
  BULK_DELETE_TAGS: () =>
    `v1/${contextType}/${contextId}/orders/bulk-delete-tags/`,
  BULK_ADD_TAGS: () => `v1/${contextType}/${contextId}/orders/bulk-add-tags/`,
  GET_CONNECTION_CREDENTIALS: () =>
    `v1/${contextType}/${contextId}/connection-credentials/`,
  CONNECTION_SUMMARY: () =>
    `v1/${contextType}/${contextId}/connections/connections-summary/`,
  POST_CONNECTION_CREDENTIALS: ({ connectionId }) =>
    `v1/${contextType}/${contextId}/connections/${connectionId}/credentials/`,
  PATCH_CONNECTION_CREDENTIALS: ({ connectionId, credentialId }) =>
    `v1/${contextType}/${contextId}/connections/${connectionId}/credentials/${credentialId}/`,
  GET_SHIPPING_ACCOUNTS: () =>
    `v1/${contextType}/${contextId}/shippingaccounts/`,
  PATCH_CONNECTION_SHIPPING_ACCOUNT: ({ connectionId }) =>
    `v1/${contextType}/${contextId}/connections/${connectionId}/shippingmethod/`,
  PUSH_ORDER: ({ id }) =>
    `v1/${contextType}/${contextId}/platform-accounts/${id}/push_order/`,
  SHOPIFY_LOOKUP_ORDER: ({ id }) =>
    `v1/${contextType}/${contextId}/shopify/${id}/lookup-order/`,
  SERIAL_KEYS: () => `v1/${contextType}/${contextId}/serial-keys/`,
  RETAILER_PREFERENCES: () =>
    `v1/${contextType}/${contextId}/retailer-preferences/`,
  DEPARTMENTS: () => `v1/${contextType}/${contextId}/departments/`,
  DEPARTMENT_BY_ID: ({ departmentId }) =>
    `v1/${contextType}/${contextId}/departments/${departmentId}/`,
  GET_COMPATIBILITY_RULESETS: () =>
    `v1/${contextType}/${contextId}/compatibility/rulesets/`,
  GET_COMPATIBILITY_RULESET_BY_ID: ({ id }) =>
    `v1/${contextType}/${contextId}/compatibility/rulesets/${id}/`,
  GET_COMPATIBILITY_RULESET_RULES: ({ id }) =>
    `v1/${contextType}/${contextId}/compatibility/rulesets/${id}/rules/`,
  RULESET_RULES_BY_RULE_ID: ({ id, ruleId }) =>
    `v1/${contextType}/${contextId}/compatibility/rulesets/${id}/rules/${ruleId}/`,
  GET_BASE_ATTRIBUTES: () => `v1/attributes/`,
  GET_RULE_GROUPS: () => `v1/rule-groups/`,
  GET_RULE_GROUP_BY_ID: ({ ruleGroupId }) =>
    `v1/${contextType}/${contextId}/rule-groups/${ruleGroupId}/`,
  GET_SHOPIFY_WEBHOOK_HISTORY: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/webhook-history/`,
  GET_SHOPIFY_WEBHOOKS: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/webhooks/`,
  GET_SHOPIFY_WEBHOOKS_BY_ID: ({ platformAccountId, webhookId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/webhooks/${webhookId}/`,
  REGISTER_SHOPIFY_WEBHOOKS: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/register-webhook/`,
  GET_SHOPIFY_LOCATIONS: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/locations/`,
  GET_SHOPIFY_ORDERS: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/orders/`,
  SHOPIFY_RETRY_ORDER: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/retry-order/`,
  SHOPIFY_BULK_UPDATE_TRACKING: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/bulk-update-tracking/`,
  PLATFORM_ACCOUNT_PUSH_INVENTORY: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/platform-accounts/${platformAccountId}/push-inventory/`,
  SHOPIFY_SYNC_PRODUCTS: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/sync-products/`,
  SHOPIFY_SYNC_VARIANT: ({ platformAccountId }) =>
    `v1/${contextType}/${contextId}/shopify/${platformAccountId}/sync-variant/`,
  GET_JOBS: () => `v1/${contextType}/${contextId}/jobs/`,
  GET_JOB_DETAILS_BY_ID: ({ id }) =>
    `v1/${contextType}/${contextId}/jobs/${id}/`,
})

const baseURLs = {
  production: 'https://marketplace-api.fabric.inc/',
  sandbox: 'https://marketplace-api-sandbox.fabric.inc/',
  staging: 'https://marketplace-api-staging.fabric.inc/',
}

export const baseURL = baseURLs[env]

const api: AxiosInstance = axios.create({
  baseURL,
})

// Request interceptor to attach the access token to outgoing requests

api.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('accessToken')
    // add logic to check for mkpAccessToken
    // const mkpAccessToken = sessionStorage.getItem('mkpAccessToken')
    // const tenant = sessionStorage.getItem('tenantDetail')
    const selectedTenantID = sessionStorage.getItem('accountId')
    // const tenantDetails = JSON.parse(tenant)
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
      config.headers['x-fabric-tenant-id'] = selectedTenantID
    }
    // initialize context when switching tenants
    if (selectedTenantID !== fabricTenantId) {
      initializeContext()
    }
    config.url = config.url.replace(
      /v1\/[^/]+\/[^/]+/,
      `v1/${contextType}/${contextId}`
    )
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const MAX_RETRY_ATTEMPTS = 3
let retryCounter = 0

// Response interceptor to handle token expiration and refresh the token
api.interceptors.response.use(
  async (response: AxiosResponse) => {
    retryCounter = 0
    return response
  },
  // refresh token logic to be added
  async (error) => {
    const { config, response } = error
    if (
      response &&
      response.status === 404 &&
      retryCounter < MAX_RETRY_ATTEMPTS
    ) {
      // If the error is due to unauthorized and retry attempts are not exhausted
      retryCounter++

      // Wait for a short duration before retrying
      await new Promise((resolve) => setTimeout(() => resolve, 1000))

      // initialize context when mkp_tenant is null
      if (!contextId && !contextType) {
        initializeContext()
      }

      // Retry the request with updated contextId and contextType
      config.url = config.url.replace(
        /v1\/[^/]+\/[^/]+/,
        `v1/${contextType}/${contextId}`
      )
      return axios(config)
    }

    // If the error is not due to unauthorized or retry attempts are exhausted, reject the promise
    return Promise.reject(error)
    // const originalRequest = error.config

    // // Check if the response status is 401 (Unauthorized) and it's not a token refresh request
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;

    //   try {
    //     const refreshToken = sessionStorage.getItem('mkpRefreshToken');
    //     if (refreshToken) {
    //       // Make a refresh token API call
    //       const refreshResponse = await axios.post(
    //         'https://marketplace-api-staging.fabric.inc/auth/refresh/token',
    //         { refreshToken }
    //       );

    //       // Update the access token in the session storage
    //       const newAccessToken = refreshResponse.data.accessToken;
    //       sessionStorage.setItem('mkpAccessToken', newAccessToken);

    //       // Retry the original request with the new access token
    //       originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
    //       return axios(originalRequest);
    //     }
    //   } catch (error) {
    //     // Handle token refresh error or other errors
    //     console.error('Failed to refresh token:', error);
    //     // Redirect to login or handle the error appropriately
    //   }
    // }

    // Handle other error cases
    // return Promise.reject(error)
  }
)

export default api
