import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSelected } from 'store/proposalProductsSlice'
import VariantItem from 'molecules/lineItem/variantItem'
import { Table } from 'atoms'
import ProductsActionsMenu from '../presenters/productActionsMenu'
import { handleAllRowSelect, handleSingleRowSelect } from 'lib/utils/table'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  selected: string[]
  options: any
  loadProposal: (quiet?: boolean) => void
  loadProducts: (quiet?: boolean) => void
  handleOpenDrawer: (id: string) => void
}

const ProposalProductsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.selected])

  const tableColumns = [
    {
      title: 'Product',
      width: '75%',
    },
    {
      title: '# of Items',
      width: '12%',
    },
    {
      title: '',
      width: '12%',
    },
  ]

  const isSelected = (id: string) => {
    return props?.selected?.includes(id)
  }

  const renderProduct = (product) =>
    VariantItem({
      variant: product,
      onClick: () => props.handleOpenDrawer(product.id),
      isProduct: true,
    })

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((product) => {
      return {
        id: product?.id,
        selected: isSelected(product?.id),
        data: {
          'Product': {
            onRender: () => renderProduct(product),
          },
          '# of Items': { value: product.num_variants },
          '': {
            onRender: () => (
              <ProductsActionsMenu
                product={product}
                options={props.options}
                isProduct={true}
                loadProposal={props.loadProposal}
                loadProducts={props.loadProducts}
              />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      loading={props.loading}
      selectable={true}
      onRowSelect={(e, rowDetails, selected) =>
        handleSingleRowSelect(e, rowDetails, selected, props.selected, setSelected)
      }
      onAllRowSelect={(e, allRowsSelected) =>
        handleAllRowSelect(e, allRowsSelected, setSelected, tableData)
      }
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setPage(pageNumber)
        },
        activePageNumber: props.currentPage,
        perPage: 10,
        totalRecords: props.totalRows,
      }}
      dataTestidPrefix='proposal-products-'
    />
  )
}

export default ProposalProductsTable
