import React from 'react'
import { SelectableCard as SelectableCardUI } from '@teamfabric/copilot-ui'

type CardProps = {
  text: string
  iconName?: string
  dataTestid?: string
  onChange?: () => void
  orientation?: 'vertical' | 'horizontal'
  assetType?: 'icon' | 'image' | 'none'
  description?: string
  imageUrl?: string
  disabled?: boolean
  width?: string
  selected?: any
}

const SelectableCard: React.FC<CardProps> = ({ ...props }) => {
  return <SelectableCardUI {...props} />
}

export default SelectableCard
