import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import HorizontalTab from 'atoms/tab'
import { getConnectionDetail } from 'api/connection'
import { getTenantType } from 'api/helper'
import { getProposalDetail } from 'api/proposals'
import { getPlatformAccounts } from 'api/platformAccount'
import UpdateCostsModal from '../components/modals/updateCosts'
import ArchiveProposalModal from '../components/modals/archiveProposal'
import ProposalHeader from '../components/details/header'
import SummaryDetails from '../components/details/summary'
import ProposalItems from '../components/details/items'
import ProposalProducts from '../components/details/products'
import Compatibility from '../components/details/compatibility'
import Issues from '../components/details/issues'
import ExportProposalModal from '../components/modals/exportProposal'
import ChangeOwnerModal from '../components/modals/changeOwner'
import ReturnProposalModal from '../components/modals/returnProposal'
import DeclineProposalModal from '../components/modals/declineProposal'
import AddToProductListModal from '../components/modals/addToProductsList'
import ApplyTransformerModal from '../components/modals/applyTransformer'
import ManagerApproveModal from '../components/modals/managerApprove'
import ApproveProposalModal from '../components/modals/approveProposal'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { useDispatch } from 'react-redux'
import { setProposalDetails } from 'store/proposalsSlice'
import { Box } from 'atoms'

const ProposalDetail: React.FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [loadingProposal, setLoadingProposal] = useState(true)
  const [loadingConnection, setLoadingConnection] = useState(true)
  const [proposal, setProposal] = useState<any>({})
  const [connection, setConnection] = useState<any>({})
  const [label, setLabel] = useState(0)
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [showUpdateCostModal, setShowUpdateCostModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showReturnModal, setShowReturnModal] = useState(false)
  const [showAddToProductsListModal, setShowAddToProductsListModal] =
    useState(false)
  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false)
  const [showApplyTransformerModal, setShowApplyTransformerModal] =
    useState(false)
  const [showDeclineProposalModal, setShowDeclineProposalModal] =
    useState(false)
  const [showManagerApproveModal, setShowManagerApproveModal] = useState(false)
  const [showApproveProductsModal, setShowApproveProductsModal] =
    useState(false)
  const [platformAccounts, setPlatformAccounts] = useState([])
  const [requirePricingApproval, setRequirePricingApproval] = useState(false)

  const { isRetailer } = getTenantType()

  useEffect(() => {
    ;(async () => {
      await fetchProposal()
      await fetchPlatformAccounts()
    })()
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          isRetailer
            ? breadcrumbs.supplierProposalDetails({ proposalID: id })
            : breadcrumbs.proposalDetails()
        ),
      0
    )
  }, [])

  const refetchProposal = async (quiet?: boolean) => {
    if (!quiet) setLoadingProposal(true)
    const { data } = await getProposalDetail({ id })
    dispatch(setProposalDetails(data))
    setProposal(data)
    setLoadingProposal(false)
  }

  const fetchProposal = async (quiet?: boolean) => {
    if (!quiet) setLoadingProposal(true)

    const { data } = await getProposalDetail({ id })
    dispatch(setProposalDetails(data))
    setProposal(data)

    if (!isEmpty(data)) {
      await fetchConnection(data, quiet)
    } else setLoadingConnection(false)

    if (
      data?.connection &&
      data?.connection?.options &&
      'require_pricing_approval_on_proposals' in data.connection.options
    ) {
      setRequirePricingApproval(true)
    }

    setLoadingProposal(false)
  }

  const fetchConnection = async (proposal: any, quiet?: boolean) => {
    if (!quiet) setLoadingConnection(true)
    const { data } = await getConnectionDetail({ id: proposal.connection?.id })
    setConnection(data)
    setLoadingConnection(false)
  }

  const fetchPlatformAccounts = async () => {
    const {
      data: { results },
    } = await getPlatformAccounts({
      params: { platform: 'shopify,fabric,bigcommerce' },
    })
    setPlatformAccounts(results)
  }

  const loading = useMemo(() => {
    return loadingProposal || loadingConnection
  }, [loadingProposal, loadingConnection])

  return (
    <>
      <UpdateCostsModal
        showModal={showUpdateCostModal}
        setShowModal={setShowUpdateCostModal}
        proposal={proposal}
        loadProposal={refetchProposal}
      />
      <ArchiveProposalModal
        showModal={showArchiveModal}
        setShowModal={setShowArchiveModal}
        proposal={proposal}
        loadProposal={refetchProposal}
      />
      {/* retailer modals */}
      {/* Export modal */}
      <ExportProposalModal
        showModal={showExportModal}
        setShowModal={setShowExportModal}
        proposal={proposal}
        loadProposal={refetchProposal}
        platformAccounts={platformAccounts}
      />
      {/* change owner modal */}
      <ChangeOwnerModal
        showModal={showChangeOwnerModal}
        setShowModal={setShowChangeOwnerModal}
        proposal={proposal}
        loadProposal={refetchProposal}
        isRetailer={isRetailer}
      />
      {/* return modal */}
      <ReturnProposalModal
        showModal={showReturnModal}
        setShowModal={setShowReturnModal}
        proposal={proposal}
        loadProposal={refetchProposal}
      />
      {/* decline proposal */}
      <DeclineProposalModal
        showModal={showDeclineProposalModal}
        setShowModal={setShowDeclineProposalModal}
        proposal={proposal}
        loadProposal={refetchProposal}
      />
      {/* complete/add to products modal */}
      <AddToProductListModal
        showModal={showAddToProductsListModal}
        setShowModal={setShowAddToProductsListModal}
        proposal={proposal}
        loadProposal={refetchProposal}
      />
      {/* Apply transformer modal */}
      <ApplyTransformerModal
        showModal={showApplyTransformerModal}
        setShowModal={setShowApplyTransformerModal}
        proposal={proposal}
        loadProposal={refetchProposal}
      />
      {/* manager approve modal */}
      <ManagerApproveModal
        showModal={showManagerApproveModal}
        setShowModal={setShowManagerApproveModal}
        proposal={proposal}
        loadProposal={refetchProposal}
      />
      {/* approve proposal */}
      <ApproveProposalModal
        showModal={showApproveProductsModal}
        setShowModal={setShowApproveProductsModal}
        proposal={proposal}
        loadProposal={refetchProposal}
        platformAccounts={platformAccounts}
      />
      <Box width={'100%'} padding={{ top: 3, right: 6, bottom: 4, left: 6 }}>
        <div className='mt-5 mb-3'>
          <ProposalHeader
            proposal={proposal}
            loading={loading}
            setShowArchiveModal={setShowArchiveModal}
            setShowSubmitModal={setShowSubmitModal}
            setUpdateCostModal={setShowUpdateCostModal}
            // retailer modals
            setShowExportModal={setShowExportModal}
            setShowReturnModal={setShowReturnModal}
            setShowAddToProductsListModal={setShowAddToProductsListModal}
            setShowChangeOwnerModal={setShowChangeOwnerModal}
            setShowApplyTransformerModal={setShowApplyTransformerModal}
            setShowDeclineProposalModal={setShowDeclineProposalModal}
            setShowManagerApproveModal={setShowManagerApproveModal}
            setShowApproveProductsModal={setShowApproveProductsModal}
            isRetailer={isRetailer}
            requirePricingApproval={requirePricingApproval}
          />
        </div>
        <HorizontalTab
          onTabChange={setLabel}
          data={{
            tabs: [
              {
                label: 'Summary',
                content: (
                  <SummaryDetails
                    loading={loading}
                    details={proposal}
                    isRetailer={isRetailer}
                    requirePricingApproval={requirePricingApproval}
                  />
                ),
              },
              {
                label: 'Products',
                content: (
                  <ProposalProducts
                    proposal={proposal}
                    isRetailer={isRetailer}
                    loadProposal={refetchProposal}
                  />
                ),
              },
              {
                label: 'Items and pricing',
                content: (
                  <ProposalItems
                    proposal={proposal}
                    isRetailer={isRetailer}
                    loadProposal={refetchProposal}
                  />
                ),
              },
              {
                label: 'Compatibility',
                content: (
                  <Compatibility proposal={proposal} isRetailer={isRetailer} />
                ),
              },
              {
                label:
                  proposal && proposal.issues_summary
                    ? `Issues (${proposal?.issues_summary?.open})`
                    : 'Issues',
                content: (
                  <Issues
                    isRetailer={isRetailer}
                    loading={loading}
                    loadProposal={refetchProposal}
                  />
                ),
              },
            ],
          }}
        />
      </Box>
    </>
  )
}

export default ProposalDetail
